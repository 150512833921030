@font-face {
  font-family: 'DIN Next LT Arabic';
  src: url('/fonts/DINNEXTLTARABIC-LIGHT-1.woff2') format('woff2'),
    url('/fonts/DINNEXTLTARABIC-LIGHT-1.woff') format('woff');
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: 'DIN Next LT Arabic';
  src: url('/fonts/DINNextLTArabic-Regular-3.woff2') format('woff2'),
    url('/fonts/DINNextLTArabic-Regular-3.woff') format('woff');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'DIN Next LT Arabic';
  src: url('/fonts/DINNextLTArabic-Medium-2.woff2') format('woff2'),
    url('/fonts/DINNextLTArabic-Medium-2.woff') format('woff');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'DIN Next LT Arabic';
  src: url('/fonts/DINNextLTArabic-Bold.woff2') format('woff2'),
    url('/fonts/DINNextLTArabic-Bold.woff') format('woff');
  font-weight: 700;
  font-display: swap;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

[dir='rtl'] .origin-top-right,
[dir='rtl'] .md\:origin-top-right {
  transform-origin: top left;
}

[dir='rtl'] .origin-top-left,
[dir='rtl'] .md\:origin-top-left {
  transform-origin: top right;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.shake {
  animation: shakeCart 0.4s ease-in-out forwards;
}

@keyframes shakeCart {
  25% {
    transform: translateX(6px);
  }

  50% {
    transform: translateX(-4px);
  }

  75% {
    transform: translateX(2px);
  }

  100% {
    transform: translateX(0);
  }
}

.react-calendar {
  font-size: 16px;
}

.react-calendar__navigation {
  padding: 0 16px;
  background-color: rgba(var(--color-fill-tertiary), var(--tw-bg-opacity));
  height: 70px;
  color: rgb(var(--color-fill-tertiary-inverted));
  font-size: 32px;
}

.header-text {
    color: rgb(var(--color-text-header));
}

.react-calendar__month-view__weekdays {
  line-height: 34px;
  font-size: 14px;
  color: #4a4a4a;
}

.react-time-picker {
  direction: ltr;
  font-size: 16px;
}

.react-time-picker__inputGroup__input {
  min-width: 60px;
  @apply rounded;
  @apply border-0;
}

.react-time-picker__inputGroup__hour {
  @apply w-2;
}

.react-time-picker__inputGroup__divider {
  @apply px-2;
}

.react-time-picker__inputGroup__input--hasLeadingZero {
  padding-left: 0;
}

.react-time-picker__inputGroup__leadingZero {
  padding-left: 12px;
}

/* .layout-bg,
.modal-bg {
  background-image: url('/bg-pattern.svg'), url('/bg-pattern.svg');
  background-repeat: no-repeat;
  background-position: right 10px, 10px calc(100% - 16px);
  background-size: 140px;
}
.modal-bg {
  background-position: left 10px, right calc(100% - 16px);
} */
